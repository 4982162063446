import { PreviewTable, PreviewTableColumn } from "@themis/ui";
import React from "react";
import { generatePath } from "react-router-dom";

import { Task } from "@/api";
import { useTasks } from "@/api/queries/tasks";
import { ErrorContainer } from "@/components/ErrorContainer";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";

import { useMyTasksFilterSort } from "../../hooks/useMyTasksFilterSort";
import { TasksEmptyState } from "../TasksEmptyState";

type Props = {
  tableColumns: PreviewTableColumn<Task>[];
  responsive?: boolean;
};

export function TasksTable({ tableColumns, responsive = false }: Props) {
  const { context } = useMainStore();
  const { listRequestQueryParams, sorting, setSorting } =
    useMyTasksFilterSort();
  const {
    data: tasksData,
    isPending,
    isError,
  } = useTasks(Number(context.companyID), listRequestQueryParams);

  if (isError) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath("/workspaces/:workspace_id/home", {
            workspace_id: context.workspaceID!,
          }),
        }}
      >
        Could not load Tasks.
      </ErrorContainer>
    );
  }

  if (!tasksData && isPending) {
    return <Loading loadingLayout="table-no-add-new" />;
  }

  return tasksData.data.length ? (
    <PreviewTable
      responsive={responsive}
      columns={tableColumns}
      rows={tasksData.data}
      sort={sorting}
      onSortChange={setSorting}
    />
  ) : (
    <TasksEmptyState />
  );
}
