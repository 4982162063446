import { StackedHorizontalBarChart } from "@themis/ui";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useDashboardComponent } from "@/api/queries/dashboardComponents";
import { HomePageSearchParams } from "@/features/misc/pages/HomePage";
import { useSearchParams } from "@/hooks/useSearchParams";

export default function WorkDistributionByModules() {
  const { workspace: workspaceId } = useParams<{ workspace: string }>();

  const [searchParams] = useSearchParams<HomePageSearchParams>();

  const { data: widgetData } = useDashboardComponent({
    systemName: "work_distribution_by_modules",
    workspaceId: Number(workspaceId),
    params: searchParams,
  });

  const mutated = useMemo(() => {
    if (!widgetData || !widgetData.data.data) {
      return {
        keys: [],
        data: [],
      };
    }
    const mutatedData: {
      name: string;
      [key: string]: string | number;
    }[] = [];
    const modules = Object.keys(widgetData.data.data);
    modules.forEach((module) => {
      const updatedName = module
        .replace(/_/gi, " ")
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
      mutatedData.push({
        name: updatedName.slice(0, 12) + (updatedName.length > 12 ? "..." : ""),
        total:
          widgetData.data.data![module].to_do +
          widgetData.data.data![module].in_progress +
          widgetData.data.data![module].overdue,
        ...widgetData.data.data![module],
      });
    });

    return {
      keys: ["to_do", "in_progress", "overdue"],
      data: mutatedData.sort((a, b) => a.name.localeCompare(b.name)),
    };
  }, [widgetData]);

  return (
    <div className="tw-flex tw-h-[260px] tw-min-w-[400px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Work Distribution by Module
        </div>
      </div>
      <div className="tw-flex tw-justify-center tw-gap-3">
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-neutral-200" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            To Do
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-primaryDim-600" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            In Review
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-warning-300" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            Overdue
          </div>
        </div>
      </div>
      <div>
        <div className="tw-h-full tw-w-full">
          <StackedHorizontalBarChart data={mutated.data} keys={mutated.keys} />
        </div>
      </div>
    </div>
  );
}
