import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { Attachment, RecordVersion } from "@/api";
import {
  getFigmaAttachment,
  getLatestAttachment,
  hasFigmaAttachment,
} from "@/components/helpers/AttachmentGroupsHelper";
import { useMainStore } from "@/contexts/Store";

import arrowLeft from "../../../../images/table-image/icon/arrow-left.svg";
import arrowRight from "../../../../images/table-image/icon/arrow-right.svg";
import { isSupportedViewFileType } from "../helpers";
import CreativeViewCaption from "./CreativeViewCaption";
import CreativeViewFigmaIframe from "./CreativeViewFigmaIframe";
import CreativeViewFile from "./CreativeViewFile";
import CreativeViewFooter from "./CreativeViewFooter";
import CreativeViewRecordVersion from "./CreativeViewRecordVersion";
import CreativeViewSidebar from "./CreativeViewSidebar";
import CreativeViewTips from "./CreativeViewTips";
import CreativeViewToolbar from "./CreativeViewToolbar";

type Props = {
  className?: string;
};

function CreativeView({ className }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [initialLoad, setInitialLoad] = useState(true);
  const [recordVersion, setRecordVersion] = useState<RecordVersion>({
    approval_config: null,
    approval_status: null,
    linked_controls: false,
    global_id: "1234",
    position: 1,
    record_id: 1,
    section_tag_id: 1,
    attachment_groups: [],
    data: {},
    id: -1,
    meta: {},
    record: {
      account_id: null,
      account: null,
      action_plans: [],
      id: -1,
      linked_findings: [],
      meta: {},
      module_workspace_id: 0,
      owner_id: null,
      shared_with_workspace_ids: [],
    },
    reviews: [],
    status: "Unknown",
    table_id: -1,
    table_name: "Default",
    uncompleted_comments_count: {
      public: 0,
      private: 0,
    },
    version: -1,
  });
  const [commentsMode, setCommentsMode] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [libraryFile, setLibraryFile] = useState({
    LibraryRecord: null,
  });

  // Variables
  const {
    fileName,
    attachmentID: currentAttachmentID,
    attachmentGroupID: currentAttachmentGroupID,
  } = mainStore.files;
  const { list } = mainStore.attachmentGroups;
  const currentAttachmentGroup = list.find(
    (attachmentGroup) => attachmentGroup.id === currentAttachmentGroupID,
  );
  const fieldName = currentAttachmentGroup?.field_name;
  const field = mainStore.fields.list.find((f) => f.name === fieldName);
  const attachmentGroups = list
    .filter(
      (attachmentGroup) =>
        attachmentGroup.field_name === currentAttachmentGroup?.field_name,
    )
    .sort((a, b) => a.id - b.id);
  const attachments = currentAttachmentGroup?.attachments || [];
  const currentAttachment = attachments.find(
    (attachment) => attachment.id === currentAttachmentID,
  );

  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id, file_id, checklist_question_id } = useParams(); // file_id is attachment_group_id
  const recordVersionID = record_version_id;
  const hasFigmaAttachmentGroup = hasFigmaAttachment(attachmentGroups);
  const moduleIdentifier = mainStore.context.themisModuleIdentifier;
  const isPolicy = moduleIdentifier === "policy";
  const isProcedure = moduleIdentifier === "procedures";
  const isTraining = moduleIdentifier === "training";
  const isMarketingModule = moduleIdentifier === "marketing";
  const isVDD = moduleIdentifier === "vendor_due_diligence";
  const isFigma = hasFigmaAttachment(attachmentGroups);
  const shouldRenderFigmaIframe = isFigma && !commentsMode;
  const status = recordVersion?.status;
  const isLocked = Boolean(
    status && ["approved", "rejected", "completed"].includes(status),
  );
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isLibrary = location.pathname.includes("document_library_preview");

  // Functions
  function getPreviousAttachment(): Attachment | undefined {
    if (!currentAttachmentGroup) {
      return undefined;
    }

    const position = attachmentGroups.indexOf(currentAttachmentGroup);
    if (position <= 0) {
      return undefined;
    }

    const previousAttachmentGroup = attachmentGroups[position - 1];

    return getLatestAttachment(previousAttachmentGroup);
  }

  function getNextAttachment(): Attachment | undefined {
    if (!currentAttachmentGroup) {
      return undefined;
    }

    const position = attachmentGroups.indexOf(currentAttachmentGroup);
    if (position === -1) {
      return undefined;
    }
    if (position >= attachmentGroups.length - 1) {
      return undefined;
    }

    const nextAttachmentGroup = attachmentGroups[position + 1];

    return getLatestAttachment(nextAttachmentGroup);
  }

  const canGoPrevious = useMemo(() => {
    return Boolean(getPreviousAttachment());
  }, [currentAttachmentGroup]);

  const canGoNext = useMemo(() => {
    return Boolean(getNextAttachment());
  }, [currentAttachmentGroup]);

  const getFigmaFile = () => {
    return getFigmaAttachment(recordVersion.attachment_groups)?.id;
  };

  const fetchFile = async (attachmentID: number) => {
    mainStore.pageLoading.startLoading();
    setFileLoading(true);

    const nextAttachmentGroup = recordVersion.attachment_groups?.find(
      (attachmentGroup) =>
        attachmentGroup.attachments.map((a) => a.id).includes(attachmentID),
    );
    const nextFieldName =
      nextAttachmentGroup?.field_name || fieldName || "creative";

    if (checklist_question_id) {
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ checklistQuestionID: any; }' i... Remove this comment to see the full error message
      await mainStore.vendors.fetchChecklistQuestionAttachment({
        checklistQuestionID: checklist_question_id,
      });
    } else if (isLibrary) {
      await mainStore.policies.fetchFileUrl(file_id);
    } else {
      recordVersionID &&
        (await mainStore.files.fetchFile({
          recordVersionID,
          attachmentID,
          fieldName: nextFieldName,
        }));
    }
  };

  const initialFetch = async () => {
    if (!file_id) {
      return;
    }

    if (isLibrary) {
      const fetchLibrary = await mainStore.policies.fetchFileUrl(file_id);

      setLibraryFile(fetchLibrary);
    } else {
      const attachmentGroupID = Number(file_id);
      const attachmentGroup = recordVersion.attachment_groups?.find(
        (ag) => ag.id === attachmentGroupID,
      );
      if (!attachmentGroup) {
        return;
      }

      const attachmentGroupFieldName = attachmentGroup.field_name;
      if (!attachmentGroupFieldName) {
        return;
      }

      recordVersionID &&
        (await mainStore.files.fetchFile({
          recordVersionID,
          attachmentGroupID,
          fieldName: attachmentGroupFieldName,
        }));
    }
  };

  async function handleChangeVersion(
    newAttachmentID: number,
    newCommentsMode = false,
  ) {
    if (currentAttachmentID === newAttachmentID) {
      return;
    }

    await fetchFile(newAttachmentID);
    setCommentsMode(newCommentsMode);
  }

  // Effects
  // fetch RV
  useEffect(() => {
    if (!record_version_id) {
      return;
    }

    const fetchRecord = async () => {
      const data = await mainStore.recordVersions.fetch(record_version_id);

      // Need to cache fields, because mainStore.fields.list is getting cleaned and it's actually what we want to have everywhere, e.g. module-specific fields storage
      if (isMarketingModule) {
        mainStore.creatives.setFields(data?.fields);
      }
    };

    fetchRecord();
  }, [record_version_id]);

  // update RV in state after current's update
  useEffect(() => {
    const { current } = mainStore.recordVersions;
    if (!current?.id) {
      return;
    }

    setRecordVersion(current);
  }, [mainStore.recordVersions.current]);

  useEffect(
    () => () => {
      mainStore.recordVersions?.setCurrent({});
    },
    [],
  );

  useEffect(
    () =>
      // cleanup files on unmount
      () =>
        mainStore.files.cleanup(),
    [],
  );

  // vdd
  useEffect(() => {
    if (!checklist_question_id || !moduleIdentifier || isLibrary) {
      return;
    }

    const fetchVdd = async () => {
      const moduleWorkspaceID = mainStore.moduleWorkspaces.list?.find(
        (mw) => mw.themis_module?.identifier === moduleIdentifier,
      )?.id;

      if (moduleIdentifier === "vendor_due_diligence") {
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ checklistQuestionID: any; modu... Remove this comment to see the full error message
        await mainStore.vendors.fetchChecklistQuestionAttachment({
          checklistQuestionID: checklist_question_id,
          moduleWorkspaceID,
        });
      }
    };

    fetchVdd();
  }, [checklist_question_id, moduleIdentifier]);

  // set cell
  useEffect(() => {
    if (file_id) {
      setCommentsMode(true);
    }
    if (isLibrary) {
      setCommentsMode(false);
    }
    if (!initialLoad) {
      return;
    }
    if (recordVersion?.id?.toString() !== record_version_id?.toString()) {
      return;
    }

    setInitialLoad(false);
    if (isFigma) {
      return;
    }

    setCommentsMode(true);
  }, [recordVersion]);

  // fetch file after cell change
  useEffect(() => {
    if (
      !checklist_question_id &&
      Object.keys(recordVersion || {}).length === 0 &&
      !isLibrary
    ) {
      return;
    }

    initialFetch();
  }, [recordVersion.id]);

  // loading screen
  useEffect(() => {
    if (fileLoading) {
      return;
    }

    setTimeout(() => mainStore.pageLoading.endLoading(), 100);
  }, [fileLoading]);

  useEffect(() => {
    if (!shouldRenderFigmaIframe) {
      setFileLoading(true);
    }
  }, [shouldRenderFigmaIframe]);

  // not supported files
  useEffect(() => {
    if (!fileName) {
      return;
    }
    if (!fileName.includes(".")) {
      return;
    }
    if (isSupportedViewFileType(fileName)) {
      return;
    }

    mainStore.pageLoading.endLoading();
  }, [fileName]);

  if (!recordVersionID && !checklist_question_id && !isLibrary) {
    return null;
  }

  const displayFooter =
    (isMarketingModule ||
      (isPolicy && !isLibrary) ||
      isProcedure ||
      isTraining) &&
    (!isLocked || (isLocked && isMarketingModule)) &&
    !isFigma &&
    ((isMarketingModule && field?.name === "creative") ||
      field?.is_file_versioning_enabled);

  return (
    <section
      className={classNames("creative-view", className)}
      data-testid="cv-container"
    >
      {!isLibrary && (
        <CreativeViewToolbar
          recordVersion={recordVersion}
          fieldName={fieldName}
          commentsMode={!isVDD && hasModuleWriteAccess && commentsMode}
          setCommentsMode={setCommentsMode}
          mainFileID={getFigmaFile()}
          fetchFile={fetchFile}
          handleChangeVersion={handleChangeVersion}
        />
      )}

      <div className="creative-content" data-testid="cv-content">
        {canGoPrevious && !isFigma && (
          <div
            data-testid="cv-arrow-left"
            onClick={() => {
              const previousAttachment = getPreviousAttachment();

              if (!previousAttachment) {
                return;
              }

              fetchFile(previousAttachment.id);
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "88px",
                height: "88px",
                background: "#F7F7F8",
                borderRadius: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={arrowLeft} alt="image" />
            </div>
          </div>
        )}

        {!isLibrary && (
          <CreativeViewRecordVersion
            fetchFile={fetchFile}
            handleChangeVersion={handleChangeVersion}
          />
        )}

        {currentAttachment && isFigma && (
          <CreativeViewFigmaIframe
            isVisible={shouldRenderFigmaIframe}
            currentURL={currentAttachment.url || undefined}
            setFileLoading={setFileLoading}
          />
        )}

        {!shouldRenderFigmaIframe && (
          <CreativeViewFile
            recordVersion={recordVersion}
            fieldName={fieldName}
            commentsMode={!isVDD && hasModuleWriteAccess && commentsMode}
            fileLoading={fileLoading}
            setFileLoading={setFileLoading}
            hasFigmaAttachmentGroup={hasFigmaAttachmentGroup}
            policyLibraryFile={libraryFile}
          />
        )}

        {canGoNext && !isFigma && (
          <div
            data-testid="cv-arrow-right"
            onClick={() => {
              const nextAttachment = getNextAttachment();

              if (!nextAttachment) {
                return;
              }

              fetchFile(nextAttachment.id);
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "88px",
                height: "88px",
                background: "#F7F7F8",
                borderRadius: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={arrowRight} alt="image" />
            </div>
          </div>
        )}

        {!isVDD && !isLibrary && (
          <CreativeViewSidebar
            // @ts-expect-error TS(2322) FIXME: Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
            attachmentGroupID={currentAttachmentGroupID}
            fetchFile={fetchFile}
            setCommentsMode={setCommentsMode}
            isFigma={isFigma}
            hasFigmaAttachmentGroup={hasFigmaAttachmentGroup}
          />
        )}
      </div>

      {isMarketingModule && !isLocked && !isFigma && (
        <CreativeViewTips recordVersion={recordVersion} />
      )}

      {!isLibrary && (
        <CreativeViewCaption
          attachmentGroup={currentAttachmentGroup}
          disabled={isLocked}
          displayFooter={displayFooter}
        />
      )}

      {displayFooter && (
        <CreativeViewFooter
          recordVersion={recordVersion}
          field={field}
          isMarketingModule={isMarketingModule}
          handleChangeVersion={handleChangeVersion}
          fetchFile={fetchFile}
        />
      )}
    </section>
  );
}

export default observer(CreativeView);
