import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  createAccount,
  deleteAccount,
  listAccounts,
  listAccountsWithLimitedResponse,
  showAccount,
  updateAccount,
} from "../gen/axios/accountsController";
import { ModifyAccountRequest } from "../gen/models/ModifyAccountRequest";

export const ACCOUNTS_QUERY_KEY = {
  all: ["accounts"],
  list: () => [...ACCOUNTS_QUERY_KEY.all, "list"],
  details: () => [...ACCOUNTS_QUERY_KEY.all, "details"],
  detail: (accountId: number) => [...ACCOUNTS_QUERY_KEY.details(), accountId],
} as const;

export function useAccounts(workspaceId: number) {
  return useQuery({
    queryKey: ACCOUNTS_QUERY_KEY.all,
    queryFn: () => listAccounts(workspaceId),
    enabled: !!workspaceId,
  });
}

export function useAccountsList(moduleWorkspaceId: number) {
  return useQuery({
    queryKey: ACCOUNTS_QUERY_KEY.list(),
    queryFn: () => listAccountsWithLimitedResponse(moduleWorkspaceId),
    enabled: !!moduleWorkspaceId,
  });
}

export function useAccount({
  workspaceId,
  accountId,
}: {
  workspaceId: number;
  accountId: number;
}) {
  return useQuery({
    queryKey: ACCOUNTS_QUERY_KEY.detail(accountId),
    queryFn: () =>
      showAccount(workspaceId, accountId, {
        expand: "contacts,contracts,documents,child_accounts",
      }),
    enabled: !!workspaceId && !!accountId,
  });
}

export function useCreateAccount({
  workspaceId,
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (account: ModifyAccountRequest) =>
      createAccount(workspaceId, { account }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ACCOUNTS_QUERY_KEY.all,
      });
      onSuccess();
    },
    onError,
  });
}

export function useUpdateAccount({
  workspaceId,
  accountId,
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId: number;
  accountId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (account: ModifyAccountRequest) =>
      updateAccount(workspaceId, accountId, { account }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ACCOUNTS_QUERY_KEY.detail(accountId),
      });
      onSuccess();
    },
    onError,
  });
}

export function useDeleteAccount({
  workspaceId,
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => deleteAccount(workspaceId, id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ACCOUNTS_QUERY_KEY.all,
      });
      onSuccess();
    },
    onError,
  });
}
