import { StackedBarChart } from "@themis/ui";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useDashboardComponent } from "@/api/queries/dashboardComponents";
import { HomePageSearchParams } from "@/features/misc/pages/HomePage";
import { useSearchParams } from "@/hooks/useSearchParams";

export default function IssuesBySource() {
  const { workspace: workspaceId } = useParams<{ workspace: string }>();

  const [searchParams] = useSearchParams<HomePageSearchParams>();

  const { data: widgetData } = useDashboardComponent({
    systemName: "issues_due_date_by_sources",
    workspaceId: Number(workspaceId),
    params: searchParams,
  });

  const mutated = useMemo(() => {
    if (!widgetData || !widgetData.data.data) {
      return {
        keys: [],
        data: [],
        colors: [],
      };
    }
    const mutatedData: {
      name: string;
      total: number;
      [key: string]: string | number;
    }[] = [];
    const ranges = Object.keys(widgetData.data.data);
    const keys: string[] = [];
    ranges.forEach((range) => {
      let total = 0;
      const sources = Object.keys(widgetData.data.data![range]);
      sources.forEach((source) => {
        if (keys.indexOf(source) === -1) {
          keys.push(source);
        }
        total += widgetData.data.data![range][source];
      });
      mutatedData.push({
        name: range,
        total,
        ...widgetData.data.data![range],
      });
    });

    return {
      keys,
      data: mutatedData,
      colors: [
        { key: "audit", color: "#8F92F9" },
        { key: "compliance", color: "#95D5F0" },
        { key: "regulatory", color: "#F042F0" },
        { key: "internal", color: "#9C001A" },
      ],
    };
  }, [widgetData]);

  return (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Issues by Source
        </div>
      </div>
      <div className="tw-flex tw-justify-center tw-gap-3">
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-primary-200" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            Audit
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-secondary-100" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            Compliance
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-fuchsia-3" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            Regulatory
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-warning-500" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            Internal
          </div>
        </div>
      </div>
      <div>
        <div className="tw-h-[212px] tw-w-full">
          <StackedBarChart
            data={mutated.data}
            keys={mutated.keys}
            colors={mutated.colors}
          />
        </div>
      </div>
    </div>
  );
}
