import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { cloneObject } from "@/api/gen/axios/functionsController";
import {
  createProject,
  getProject,
  listProjects,
  updateProject,
} from "@/api/gen/axios/projectsController";

import {
  CloneObjectMutationRequest,
  CreateProjectMutationRequest,
  ListProjectsQueryParams,
  UpdateProjectMutationRequest,
} from "../gen";

export const PROJECTS_QUERY_KEY = {
  all: ["projects"],
  detail: (projectId: number) => [...PROJECTS_QUERY_KEY.all, projectId],
} as const;

export function useProjects(
  companyId: number,
  queryParams: ListProjectsQueryParams = {},
) {
  return useQuery({
    queryKey: [...PROJECTS_QUERY_KEY.all, queryParams],
    queryFn: () => listProjects(companyId, queryParams),
    enabled: !!companyId && !!queryParams,
    placeholderData: (prevData) => prevData,
  });
}

export function useCreateProject({
  companyId,
  onSuccess = () => {},
  onError = () => {},
}: {
  companyId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (project: CreateProjectMutationRequest) =>
      createProject(companyId, project),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: PROJECTS_QUERY_KEY.all,
      });
      onSuccess();
    },
    onError,
  });
}

export function useCloneProject({
  projectId,
  onSuccess = () => {},
  onError = () => {},
}: {
  projectId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (cloneObjectMutationRequest: CloneObjectMutationRequest) =>
      cloneObject("projects", projectId, cloneObjectMutationRequest),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: PROJECTS_QUERY_KEY.all,
      });
      onSuccess();
    },
    onError,
  });
}

export function useUpdateProject({
  projectId,
  onSuccess = () => {},
  onError = () => {},
}: {
  projectId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (project: UpdateProjectMutationRequest) =>
      updateProject(projectId, project),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: PROJECTS_QUERY_KEY.all,
      });
      onSuccess();
    },
    onError,
  });
}

export function useProject({
  workspaceId,
  projectId,
}: {
  workspaceId: number;
  projectId: number;
}) {
  return useQuery({
    queryKey: PROJECTS_QUERY_KEY.detail(projectId),
    queryFn: () => getProject(projectId),
    enabled: !!workspaceId && !!projectId,
  });
}
