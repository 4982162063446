import { Checkbox, useToast } from "@themis/ui";
import React from "react";

import { Task } from "@/api";
import { useUpdateTask } from "@/api/queries/tasks";
import { useMainStore } from "@/contexts/Store";

export default function TaskCheckboxCell({ task }: { task: Task }) {
  const {
    context: { companyID },
  } = useMainStore();

  const toast = useToast();
  const { mutateAsync: updateTask } = useUpdateTask({
    companyId: companyID!,
  });

  async function handleCheckChange(checked: boolean | string) {
    try {
      await updateTask({
        id: task.id,
        task: {
          status: checked ? "Done" : "In Progress",
        },
      });
      toast({
        content: "Task marked as Complete",
        variant: "success",
      });
    } catch {
      toast({
        content: "Failed to mark task as Complete",
        variant: "error",
      });
    }
  }
  return (
    <Checkbox
      rounded
      color="primary"
      checked={task.status === "Done"}
      onCheckedChange={handleCheckChange}
      data-tooltip-id="tooltip"
      data-tooltip-place="bottom-start"
      data-tooltip-content="Mark as complete"
    />
  );
}
