import { PieChart } from "@themis/ui";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useDashboardComponent } from "@/api/queries/dashboardComponents";
import { HomePageSearchParams } from "@/features/misc/pages/HomePage";
import { useSearchParams } from "@/hooks/useSearchParams";

const PIE_COLORS = [
  "#8F92F9",
  "#95D5F0",
  "#F042F0",
  "#9C001A",
  "#FDB932",
  "#474DEB",
  "#FFBEC9",
  "#AA75F0",
];
export default function WorkflowsWithDueDatesApproaching() {
  const { workspace: workspaceId } = useParams<{ workspace: string }>();

  const [searchParams] = useSearchParams<HomePageSearchParams>();

  const { data: widgetData } = useDashboardComponent({
    systemName: "workflows_with_due_dates_approaching",
    workspaceId: Number(workspaceId),
    params: searchParams,
  });

  const mutated = useMemo(() => {
    const keys = Object.keys(widgetData?.data?.data || {});
    if (keys.length === 0) {
      return [];
    }
    return keys
      .sort((a, b) => a.localeCompare(b))
      .map((key, index) => ({
        name:
          key === "policy"
            ? "Policies"
            : key
                .replace(/_/gi, " ")
                .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase(),
                ),
        value: widgetData?.data?.data![key],
        color: PIE_COLORS[index],
      }));
  }, [widgetData]);

  const valueSum = mutated.reduce((acc, curr) => acc + curr.value, 0);

  return (
    <div className="tw-flex tw-h-[260px] tw-min-w-[400px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Workflows with Due Dates Approaching
        </div>
      </div>
      <div>
        <div className="tw-flex tw-h-[228px] tw-w-full tw-justify-evenly">
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
            <div className="tw-text-6xl tw-font-semibold tw-text-neutral-500">
              {valueSum}
            </div>
            <div className="tw-text-sm tw-font-medium tw-text-neutral-300">
              Workflow Due Dates
            </div>
          </div>
          <div className="tw-flex tw-flex-col">
            <PieChart data={mutated} />
            <div className="tw-text-sm tw-font-medium tw-text-neutral-300">
              % Module Workflow
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
