var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import * as React from "react";
import { PiCaretDownBold } from "react-icons/pi";
import { cn } from "../../lib/utils";
var Accordion = AccordionPrimitive.Root;
var AccordionItem = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement(AccordionPrimitive.Item, __assign({ ref: ref, className: cn("tw-border-b", className) }, props)));
});
AccordionItem.displayName = "AccordionItem";
/**
 * AccordionTrigger component
 *
 * @param {Object} props - The component props
 * @param {string} [props.className] - Additional CSS classes to apply to the trigger
 * @param {React.ReactNode} props.children - The content of the trigger
 * @param {IconPosition} [props.iconPosition='right'] - The position of the icon ('left' or 'right')
 */
var AccordionTrigger = React.forwardRef(function (_a, ref) {
    var className = _a.className, children = _a.children, _b = _a.iconPosition, iconPosition = _b === void 0 ? "right" : _b, props = __rest(_a, ["className", "children", "iconPosition"]);
    return (React.createElement(AccordionPrimitive.Header, { className: "tw-flex" },
        React.createElement(AccordionPrimitive.Trigger, __assign({ ref: ref, className: cn("tw-flex tw-flex-1 tw-items-center tw-py-4 tw-text-sm tw-font-medium tw-transition-all [&[data-state=open]>svg]:tw-rotate-180", {
                "tw-justify-start tw-gap-1": iconPosition === "left",
                "tw-justify-between": iconPosition === "right",
            }, className) }, props),
            iconPosition === "left" && (React.createElement(PiCaretDownBold, { className: "tw-h-4 tw-w-4 tw-shrink-0 tw-transition-transform tw-duration-200" })),
            children,
            iconPosition === "right" && (React.createElement(PiCaretDownBold, { className: "tw-h-4 tw-w-4 tw-shrink-0 tw-transition-transform tw-duration-200" })))));
});
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;
var AccordionContent = React.forwardRef(function (_a, ref) {
    var className = _a.className, children = _a.children, props = __rest(_a, ["className", "children"]);
    return (React.createElement(AccordionPrimitive.Content, __assign({ ref: ref, className: "tw-overflow-hidden tw-text-sm tw-transition-all data-[state=closed]:tw-animate-accordion-up data-[state=open]:tw-animate-accordion-down" }, props),
        React.createElement("div", { className: cn("tw-pb-4 tw-pt-0", className) }, children)));
});
AccordionContent.displayName = AccordionPrimitive.Content.displayName;
export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
