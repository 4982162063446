import React, { useEffect } from "react";
import { PiArchiveBold } from "react-icons/pi";

import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";

import { MY_TASKS_ROUTES } from ".";
import { FilterOperand } from "../../../hooks/useFilterSort";
import { TasksTable } from "../components/old-table/TasksTable";
import { archivedTableColumns } from "../config/myTasksTableConfig";
import { useMyTasksFilterSort } from "../hooks/useMyTasksFilterSort";

export default function Archived() {
  const { addFilter } = useMyTasksFilterSort();

  useEffect(() => {
    addFilter("archived_at", { operand: FilterOperand.PRESENT });
  }, []);

  return (
    <PageLayout>
      <Header
        backButtonLinkTo={MY_TASKS_ROUTES.base}
        title={
          <div className="tw-flex tw-items-center tw-gap-2">
            <PiArchiveBold />
            Archived Tasks
          </div>
        }
      />
      <PageContent>
        <TasksTable tableColumns={archivedTableColumns} responsive />
      </PageContent>
    </PageLayout>
  );
}
