import { observer } from "mobx-react";
import React from "react";
import { Route, Switch } from "react-router-dom";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import FavoriteButton from "@/features/misc/favorites/FavoriteButton";
import mainStore from "@/stores/Main";

import TodosDetailView from "./TodosDetailView";
import TodosIndex from "./TodosIndex";

function Todos() {
  return (
    <>
      <DashboardHeader
        title={"Checklists"}
        LeftActionBar={<ViewModuleUsers />}
        passedFavButton={
          <FavoriteButton
            passedModuleWorkspaceID={
              mainStore.moduleWorkspaces.list.find(
                (mw) => mw.themis_module.identifier === "checklists",
              )?.id
            }
          />
        }
      />
      <DashboardContent>
        <Switch>
          <Route path="/todos/add-new-checklist">
            <DashboardContentWrapper>
              <TodosDetailView />
            </DashboardContentWrapper>
          </Route>
          <Route path="/todos/:id">
            <DashboardContentWrapper>
              <TodosDetailView />
            </DashboardContentWrapper>
          </Route>
          <Route path="/todos">
            <DashboardContentWrapper>
              <TodosIndex />
            </DashboardContentWrapper>
          </Route>
        </Switch>
      </DashboardContent>
    </>
  );
}

export default observer(Todos);
