import React from "react";

import { MonitoringGroupAssetChildResult } from "@/api";

import { StatusTag } from "../components/StatusTag";
import { ThumbsStatus } from "../components/ThumbsStatus";
import ViewSlideButton from "../components/ViewSlideButton";

export const MONITORING_GROUP_ASSET_CHILD_RESULTS_COLUMNS_TABLE = [
  {
    headerName: "",
    width: 30,
    rowDrag: true,
    resizable: false,
  },
  {
    headerName: "Rule",
    cellRenderer: "textRenderCell",
    field: "rule",
    editable: false,
    minWidth: 250,
  },
  {
    cellRenderer: "textRenderCell",
    field: "reason",
    headerName: "Reason",
    editable: false,
    minWidth: 450,
  },
  {
    headerName: "Severity",
    cellRenderer: (props: { data: MonitoringGroupAssetChildResult }) => {
      return (
        <div className="tw-pl-2">
          <StatusTag status={props.data.severity || ""} />
        </div>
      );
    },
    editable: false,
    minWidth: 110,
    flex: 3,
  },
  {
    headerName: "Status",
    cellRenderer: (props: { data: MonitoringGroupAssetChildResult }) => {
      return (
        <div className="tw-pl-2">
          <StatusTag status={props.data.status || ""} />
        </div>
      );
    },
    editable: false,
    minWidth: 160,
    flex: 3,
  },
  {
    cellRenderer: (props: { data: MonitoringGroupAssetChildResult }) => {
      return (
        <div className="tw-flex tw-items-center tw-justify-center">
          <ThumbsStatus
            monitoringGroupResultId={props.data.id}
            disable={props.data.status !== "Unreviewed"}
          />
        </div>
      );
    },
    minWidth: 100,
    flex: 2,
  },
  {
    cellRenderer: (props: { data: MonitoringGroupAssetChildResult }) => {
      return (
        <div className="tw-flex tw-items-center tw-justify-center">
          <ViewSlideButton monitoringGroupResultId={props.data.id} />
        </div>
      );
    },
    minWidth: 50,
    flex: 2,
    pinned: "right",
  },
];

export const MONITORING_GROUP_ASSET_CHILD_RESULTS_MISSING_COLUMNS_TABLE = [
  {
    headerName: "",
    width: 30,
    rowDrag: true,
    resizable: false,
  },
  {
    headerName: "Copy",
    cellRenderer: "textRenderCell",
    field: "copy_title_data",
    editable: false,
    minWidth: 820,
  },
  {
    headerName: "Status",
    cellRenderer: (props: { data: MonitoringGroupAssetChildResult }) => {
      return (
        <div className="tw-pl-2">
          <StatusTag status={props.data.status || ""} />
        </div>
      );
    },
    editable: false,
    minWidth: 160,
    flex: 3,
  },
  {
    cellRenderer: (props: { data: MonitoringGroupAssetChildResult }) => {
      return (
        <div className="tw-flex tw-items-center tw-justify-center">
          <ThumbsStatus
            monitoringGroupResultId={props.data.id}
            disable={props.data.status !== "Unreviewed"}
          />
        </div>
      );
    },
    minWidth: 100,
    flex: 2,
  },
  {
    cellRenderer: (props: { data: MonitoringGroupAssetChildResult }) => {
      return (
        <div className="tw-flex tw-items-center tw-justify-center">
          <ViewSlideButton monitoringGroupResultId={props.data.id} />
        </div>
      );
    },
    minWidth: 50,
    flex: 2,
    pinned: "right",
  },
];
