import {
  Avatar,
  getInitials,
  SelectItem,
  Table,
  TableComponent,
  useToast,
} from "@themis/ui";
import {
  NewValueParams,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { TableProps } from "packages/ui/src/components/Table/types";
import React, { useMemo } from "react";
import { generatePath } from "react-router-dom";

import { Task, ThemisRecord } from "@/api";
import { useTasks, useUpdateTask } from "@/api/queries/tasks";
import { useCompanyUsers } from "@/api/queries/users";
import { ErrorContainer } from "@/components/ErrorContainer";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";

import { TaskStatus } from "../../config/status";
import { useMyTasksFilterSort } from "../../hooks/useMyTasksFilterSort";
import AssociatedRecordCell from "../old-table/AssociatedRecordCell";
import { TasksEmptyState } from "../TasksEmptyState";

type Column<TEntity extends ThemisRecord> = Omit<
  TableProps["columns"][number],
  "field"
> & {
  field?: keyof TEntity;
};

type Columns = Array<Column<Task>>;

function TasksTable() {
  const { context, taskDetail } = useMainStore();
  const toast = useToast();
  const { listRequestQueryParams, sorting, setSorting } =
    useMyTasksFilterSort();
  const {
    data: users,
    isPending: isUsersPending,
    isError: isUsersError,
  } = useCompanyUsers(Number(context.companyID));
  const {
    data: tasksData,
    isPending: isTasksPending,
    isError: isTasksError,
  } = useTasks(Number(context.companyID), listRequestQueryParams);
  const isPending = isUsersPending || isTasksPending;
  const isError = isUsersError || isTasksError;
  const { mutateAsync: updateTask } = useUpdateTask({
    companyId: Number(context.companyID),
  });

  const columns: Columns = useMemo(
    () => [
      {
        headerName: "",
        width: 30,
        minWidth: 30,
        editable: true,
        cellRenderer: TableComponent.checkboxCell,
        cellEditor: TableComponent.checkboxCell,
        valueGetter: (params: ValueGetterParams<Task>) =>
          params.data?.status === TaskStatus.Done.value,
        valueSetter: (params: ValueSetterParams<Task>) => {
          params.data.status = params.newValue
            ? TaskStatus.Done.value
            : TaskStatus["In Progress"].value;
          return true;
        },
        onCellValueChanged: async (params: NewValueParams<Task, boolean>) => {
          try {
            await updateTask({
              id: params.data.id,
              task: {
                status: params.newValue
                  ? TaskStatus.Done.value
                  : TaskStatus["In Progress"].value,
              },
            });
            toast({
              content: `Task marked as ${
                params.newValue
                  ? TaskStatus.Done.label.toLowerCase()
                  : TaskStatus["In Progress"].label.toLowerCase()
              } successfully!`,
              variant: "success",
            });
          } catch (error) {
            params.api.applyTransaction({
              update: [
                {
                  ...params.data,
                  status: params.oldValue
                    ? TaskStatus.Done.value
                    : TaskStatus["In Progress"].value,
                },
              ],
            });
            toast({
              content: "Something went wrong marking task completed",
              variant: "error",
            });
          }
        },
        sortable: false,
      },
      {
        headerName: "Name",
        field: "name",
        cellRenderer: TableComponent.identifierCellRenderer,
        cellRendererParams: (props: CustomCellRendererProps<Task>) => ({
          onClick: () => taskDetail.open(props.data),
        }),
        width: 300,
        minWidth: 200,
        flex: 2,
        initialSort:
          sorting?.columnKey === "name" ? sorting.direction : undefined,
        comparator: (_, __, nodeA, nodeB) => {
          const a = nodeA.data.name.toLowerCase();
          const b = nodeB.data.name.toLowerCase();

          if (a === b) {
            return 0;
          }
          return a < b ? -1 : 1;
        },
      },
      {
        headerName: "Status",
        field: "status",
        editable: false,
        cellRenderer: TableComponent.selectCell,
        cellRendererParams: {
          items: Object.values(TaskStatus).map((status) => ({
            label: status.label,
            value: status.value,
          })),
          renderSelected: ({ value }: Partial<SelectItem>) => {
            return TaskStatus[value as keyof typeof TaskStatus].Component();
          },
        },
        minWidth: 120,
        initialSort:
          sorting?.columnKey === "status" ? sorting.direction : undefined,
      },
      {
        headerName: "Due Date",
        field: "due_date",
        editable: false,
        valueGetter: (params: ValueGetterParams<Task>) =>
          params?.data?.due_date ? dayjs(params.data?.due_date).toDate() : null,
        cellRenderer: TableComponent.datePickerCell,
        cellRendererParams: {
          mode: "single",
        },
        minWidth: 120,
        initialSort:
          sorting?.columnKey === "due_date" ? sorting.direction : undefined,
      },
      {
        headerName: "Assignee",
        field: "assignee_id",
        editable: false,
        valueGetter: (params: ValueGetterParams<Task>) =>
          String(params.data?.assignee_id) || [],
        cellRenderer: TableComponent.selectCell,
        cellRendererParams: (props: CustomCellRendererProps<Task>) => {
          const userOptions =
            users?.data.map((user) => ({
              label: user.full_name || "No Name",
              value: String(user.id),
              Component: () => (
                <div className="tw-flex tw-items-center tw-gap-1">
                  <Avatar size="md" colorIndex={user.icon_color_index}>
                    {user.initials}
                  </Avatar>
                  <span>{user.full_name}</span>
                </div>
              ),
            })) || [];

          return {
            ...props,
            items: userOptions,
            renderSelected: ({
              label,
              value,
              Component: Component,
            }: Partial<SelectItem>) => {
              return (
                <div className="tw-mr-1" key={value}>
                  {Component &&
                    Component({
                      label: getInitials(label || ""),
                      value: value || "",
                    })}
                </div>
              );
            },
          };
        },
        minWidth: 200,
        flex: 2,
        initialSort:
          sorting?.columnKey === "assignee_id" ? sorting.direction : undefined,
        comparator: (_, __, nodeA, nodeB, isDesc) => {
          const a =
            users?.data
              .find((user) => user.id === nodeA.data.assignee_id)
              ?.full_name?.toLowerCase() || "";
          const b =
            users?.data
              .find((user) => user.id === nodeB.data.assignee_id)
              ?.full_name?.toLowerCase() || "";

          if (a === b) {
            return 0;
          }
          if (!a) {
            return isDesc ? 1 : -1;
          }
          if (!b) {
            return isDesc ? -1 : 1;
          }
          return a < b ? -1 : 1;
        },
      },
      {
        headerName: "Collaborators",
        field: "collaborator_ids",
        editable: false,
        valueGetter: (params: ValueGetterParams<Task>) =>
          params.data?.collaborator_ids.map(String) || [],
        cellRenderer: TableComponent.selectCell,
        cellRendererParams: (props: CustomCellRendererProps<Task>) => {
          const userOptions =
            users?.data.map((user) => ({
              label: user.full_name || "No Name",
              value: String(user.id),
              Component: () => (
                <Avatar size="md" colorIndex={user.icon_color_index}>
                  {user.initials}
                </Avatar>
              ),
            })) || [];

          return {
            ...props,
            multiple: true,
            items: userOptions,
            renderSelected: ({
              label,
              value,
              Component: Component,
            }: Partial<SelectItem>) => {
              return (
                <div className="tw-mr-1" key={value}>
                  {Component &&
                    Component({
                      label: getInitials(label || ""),
                      value: value || "",
                    })}
                </div>
              );
            },
          };
        },
        minWidth: 150,
        sortable: false,
      },
      {
        headerName: "Associated Records",
        field: "taskables",
        editable: false,
        cellRenderer: (params: CustomCellRendererProps<Task>) =>
          params.data && (
            <div className="tw-px-2.5">
              <AssociatedRecordCell task={params.data} />
            </div>
          ),
        minWidth: 300,
        sortable: false,
      },
    ],
    [tasksData, users, sorting],
  );

  function handleSortChange(column: string | null, direction?: "asc" | "desc") {
    setSorting(
      column
        ? {
            columnKey: column as keyof Task,
            direction: direction as "asc" | "desc",
          }
        : undefined,
    );
  }

  if (isError) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath("/workspaces/:workspace_id/home", {
            workspace_id: context.workspaceID!,
          }),
        }}
      >
        Could not load Tasks.
      </ErrorContainer>
    );
  }

  if (isPending) {
    return <Loading loadingLayout="table-no-add-new" />;
  }

  if (!tasksData) {
    return <TasksEmptyState />;
  }

  return tasksData.data.length ? (
    <Table
      width="100%"
      columns={columns}
      rows={tasksData.data}
      cellEditingStopped={() => {}}
      onSortChange={handleSortChange}
    />
  ) : (
    <TasksEmptyState />
  );
}

export default observer(TasksTable);
