import { useQuery } from "@tanstack/react-query";

import { CUSTOM_TIME_FRAME } from "@/features/misc/pages/HomePage";

import { getDashboardComponent } from "../gen/axios/dashboardsController";

export const DASHBOARD_COMPONENTS_QUERY_KEY = {
  all: ({
    systemName,
    params,
  }: {
    systemName: string;
    params: DashboardComponentParams;
  }) => ["dashboard_components", systemName, params],
} as const;

type DashboardComponentParams = {
  days?: string;
  start_date?: string;
  end_date?: string;
};

export function useDashboardComponent({
  systemName,
  workspaceId,
  params,
}: {
  systemName: string;
  workspaceId: number;
  params: DashboardComponentParams;
}) {
  const { days, start_date, end_date } = params;

  return useQuery({
    queryKey: DASHBOARD_COMPONENTS_QUERY_KEY.all({ systemName, params }),
    queryFn: () => {
      function getParams() {
        if (days === CUSTOM_TIME_FRAME) {
          return { start_date, end_date };
        }

        if (!days) {
          return { days: "30" };
        }

        return { days };
      }

      return getDashboardComponent(systemName, workspaceId, {
        params: getParams(),
      });
    },
    enabled: !!workspaceId,
  });
}
