import React from "react";
import { Bar, BarChart, Text, XAxis, YAxis } from "recharts";
export var StackedHorizontalBarChart = function (_a) {
    var data = _a.data, _b = _a.colors, colors = _b === void 0 ? ["#BCBCCF", "#313181", "#EB2E4E"] : _b, _c = _a.keys, keys = _c === void 0 ? ["value1", "value2", "value3"] : _c;
    var stackedBars = keys.map(function (key, index) {
        return index === 0 ? (React.createElement(Bar, { key: "bar-".concat(key), dataKey: key, stackId: "a", fill: colors[index], barSize: 12 })) : (React.createElement(Bar, { key: "bar-".concat(key), dataKey: key, stackId: "a", fill: colors[index], barSize: 12 }));
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var CustomAxisTick = function (props) {
        var _a;
        var x = props.x, y = props.y, payload = props.payload;
        var total = (_a = data.find(function (d) { return d.name === payload.value; })) === null || _a === void 0 ? void 0 : _a.total;
        return (React.createElement("g", null,
            React.createElement(Text, { x: x - 135, y: y, verticalAnchor: "middle", fill: "#353549", fontFamily: "Poppins", fontSize: 12, fontWeight: 500 }, payload.value),
            React.createElement(Text, { x: x - 4, y: y, textAnchor: "end", verticalAnchor: "middle", fill: "#353549", fontFamily: "Poppins", fontSize: 12, fontWeight: 600 }, total)));
    };
    return (React.createElement(BarChart, { layout: "vertical", width: 400, height: 216, data: data, margin: {
            left: 85,
        } },
        React.createElement(XAxis, { type: "number", tickCount: 0 }),
        React.createElement(YAxis, { dataKey: "name", type: "category", interval: 0, tickLine: false, tick: CustomAxisTick, axisLine: {
                stroke: "#E4E4EB",
                strokeWidth: 1,
            } }),
        stackedBars.reverse()));
};
