import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  IconButton,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@themis/ui";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiPencilSimpleLineBold } from "react-icons/pi";

import IssuesByRisk from "@/components/charts/widgets/WorkspaceOverview/IssuesByRisk";
import IssuesBySource from "@/components/charts/widgets/WorkspaceOverview/IssuesBySource";
import IssuesWithDueDatesApproachingStat from "@/components/charts/widgets/WorkspaceOverview/IssuesWithDueDatesApproachingStat";
import LibraryRecordByStages from "@/components/charts/widgets/WorkspaceOverview/LibraryRecordByStages";
import LibraryRecordsWithDueDatesApproaching from "@/components/charts/widgets/WorkspaceOverview/LibraryRecordsWithDueDatesApproaching";
import WorkDistributionByModules from "@/components/charts/widgets/WorkspaceOverview/WorkDistributionByModules";
import WorkflowsWithDueDatesApproaching from "@/components/charts/widgets/WorkspaceOverview/WorkflowsWithDueDatesApproaching";
import { TimeFrameSelect } from "@/components/TimeFrameSelect";
import { DATE_FORMAT } from "@/constants";
import { useMainStore } from "@/contexts/Store";
import { useIsFeatureEnabled } from "@/hooks/useIsFeatureEnabled";
import { useSearchParams } from "@/hooks/useSearchParams";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

import { ActionItems } from "../../../components/home/action-items/ActionItems";
import HomeContent from "../../../components/home/content/HomeContent";
import AnnouncementHeader from "../../../components/home/header/AnnouncementHeader";
import HomeHeader from "../../../components/home/header/HomeHeader";
import { CustomTimeRangeModal } from "../CustomTimeRangeModal";

export type HomePageSearchParams = {
  days?: string;
  start_date?: string;
  end_date?: string;
};

const DEFAULT_TIME_FRAME = "30" as const;

export const CUSTOM_TIME_FRAME = "custom" as const;

const TIME_FRAME_OPTIONS = [
  { value: "1", label: "1 Day" },
  { value: "7", label: "1 Week" },
  { value: "30", label: "1 Month" },
  { value: "90", label: "3 Months" },
  { value: "180", label: "6 Months" },
  { value: "365", label: "1 Year" },
  { value: CUSTOM_TIME_FRAME, label: "Custom Time Range" },
];

function HomePage() {
  const { users, announcements } = useMainStore();

  const [tabName, setTabName] = useState("my_work_tab_trigger");
  const [isCustomTimeFrameModalOpen, setIsCustomTimeFrameModalOpen] =
    useState(false);

  const isOutlookEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_NEW_DASHBOARD_WIDGETS,
  );

  const isRetroEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_NEW_DASHBOARD_RETROSPECTIVE_WIDGETS,
  );

  const [searchParams, setSearchParams] =
    useSearchParams<HomePageSearchParams>();

  const selectedTimeFrame = searchParams.days || DEFAULT_TIME_FRAME;

  function handleOpenCustomTimeFrameModal() {
    setIsCustomTimeFrameModalOpen(true);
  }

  function handleTimeFrameSelect(value: string) {
    if (value === CUSTOM_TIME_FRAME) {
      handleOpenCustomTimeFrameModal();

      return;
    }

    setSearchParams({ ...searchParams, days: value }, true);
  }

  function handleCloseCustomTimeFrameModal() {
    setIsCustomTimeFrameModalOpen(false);
  }

  function handleSetCustomTimeRange({
    start_date,
    end_date,
  }: {
    start_date: string;
    end_date: string;
  }) {
    handleCloseCustomTimeFrameModal();

    setSearchParams(
      {
        ...searchParams,
        days: CUSTOM_TIME_FRAME,
        start_date,
        end_date,
      },
      true,
    );
  }

  if (isOutlookEnabled === undefined && isRetroEnabled === undefined) {
    return null;
  }

  return (
    <div className="dashboard-content">
      <div className="dashboard-content-wrap home-page tw-min-h-screen tw-pb-10">
        {isOutlookEnabled || isRetroEnabled ? (
          <div className="tw-mb-2 tw-flex tw-min-h-fit tw-w-screen tw-flex-col tw-overflow-y-auto  tw-px-6">
            <div className="tw-sticky tw-top-0 tw-z-10 tw-box-border tw-flex tw-min-h-11 tw-justify-between tw-bg-white tw-pt-2 tw-text-base tw-font-semibold tw-text-neutral-500">
              <div>Good Morning, {users.user?.full_name}</div>
              {tabName === "company_outlook_tab_trigger" && (
                <div className="tw-flex tw-items-center tw-gap-2">
                  {searchParams.days === CUSTOM_TIME_FRAME && (
                    <div className="tw-flex tw-items-center tw-gap-1">
                      <p className="tw-text-xs tw-font-medium tw-text-neutral-300">
                        {dayjs(searchParams.start_date).format(DATE_FORMAT)} -{" "}
                        {dayjs(searchParams.end_date).format(DATE_FORMAT)}
                      </p>
                      <IconButton
                        size="sm"
                        color="transparent"
                        Icon={PiPencilSimpleLineBold}
                        onClick={handleOpenCustomTimeFrameModal}
                      />
                    </div>
                  )}
                  <TimeFrameSelect
                    options={TIME_FRAME_OPTIONS}
                    selectedValue={selectedTimeFrame}
                    onSelect={handleTimeFrameSelect}
                  />
                  {isCustomTimeFrameModalOpen && (
                    <CustomTimeRangeModal
                      onClose={handleCloseCustomTimeFrameModal}
                      onSetCustomTimeRange={handleSetCustomTimeRange}
                    />
                  )}
                </div>
              )}
            </div>
            <Tabs
              value={tabName}
              onValueChange={setTabName}
              className="tw-border-0"
            >
              <TabsList className="tw-sticky tw-top-11 tw-z-10 tw-h-[34px] tw-gap-4 tw-rounded-none tw-border-0 tw-border-b-2 tw-border-solid tw-border-primaryDim-100 tw-pb-0">
                <TabsTrigger
                  value="my_work_tab_trigger"
                  className="tw-rounded-none tw-p-0 tw-font-medium tw-text-neutral-300 hover:tw-bg-primaryDim-25  data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2  data-[state=active]:tw-border-solid data-[state=active]:tw-border-primaryDim-300 data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-text-primaryDim-300"
                >
                  My Work
                </TabsTrigger>
                {isOutlookEnabled && (
                  <TabsTrigger
                    value="company_outlook_tab_trigger"
                    className="tw-rounded-none tw-p-0 tw-font-medium tw-text-neutral-300 hover:tw-bg-primaryDim-25  data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2  data-[state=active]:tw-border-solid data-[state=active]:tw-border-primaryDim-300 data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-text-primaryDim-300"
                  >
                    Company Outlook
                  </TabsTrigger>
                )}
                {isRetroEnabled && (
                  <TabsTrigger
                    value="company_retrospective_tab_trigger"
                    className="tw-rounded-none tw-p-0 tw-font-medium tw-text-neutral-300 hover:tw-bg-primaryDim-25  data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2  data-[state=active]:tw-border-solid data-[state=active]:tw-border-primaryDim-300 data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-text-primaryDim-300"
                  >
                    Company Retrospective
                  </TabsTrigger>
                )}
              </TabsList>
              <TabsContent value="my_work_tab_trigger">
                <div
                  className="home-page__main-panel"
                  data-testid="home-header"
                >
                  <HomeContent />
                </div>
              </TabsContent>
              {isOutlookEnabled && (
                <TabsContent value="company_outlook_tab_trigger">
                  <div className="tw-flex tw-w-full tw-flex-col tw-gap-[14px]">
                    <header className="tw-flex tw-items-center tw-justify-between tw-self-stretch">
                      <h2 className="tw-text-base tw-font-semibold tw-text-neutral-500">
                        Workspace Overview
                      </h2>
                    </header>
                    <div className="tw-flex tw-w-full tw-flex-col tw-gap-4">
                      <div className="tw-flex tw-flex-wrap tw-gap-4">
                        <LibraryRecordByStages />
                        <LibraryRecordsWithDueDatesApproaching />
                      </div>
                      <div className="tw-flex tw-flex-wrap tw-gap-4">
                        <WorkflowsWithDueDatesApproaching />
                        <WorkDistributionByModules />
                      </div>
                      <div className="tw-flex tw-flex-wrap tw-gap-4">
                        <Accordion
                          type="single"
                          collapsible
                          defaultValue="item-1"
                          className="tw-mb-4 tw-w-full"
                        >
                          <AccordionItem value="item-1">
                            <AccordionTrigger iconPosition="left">
                              <span className="tw-text-base tw-font-semibold">
                                Issue Management
                              </span>
                            </AccordionTrigger>
                            <AccordionContent className="tw-flex">
                              <div className="tw-min-w-screen tw-flex tw-w-screen tw-flex-wrap tw-gap-4 tw-p-1">
                                <IssuesWithDueDatesApproachingStat />
                                <IssuesByRisk />
                                <IssuesBySource />
                              </div>
                            </AccordionContent>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </TabsContent>
              )}
              {isRetroEnabled && (
                <TabsContent value="company_retrospective_tab_trigger">
                  <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-pt-72">
                    <div className="tw-flex tw-w-96 tw-flex-col tw-justify-center">
                      <div className="tw-text-center tw-text-base tw-font-semibold tw-text-neutral-500">
                        Coming soon!
                      </div>
                      <div className="tw-w-96 tw-text-center tw-text-xs tw-font-medium tw-tracking-wider tw-text-neutral-300">
                        Exciting news! We&apos;re gearing up to introduce a new
                        feature that will revolutionize your experience. Soon,
                        you&apos;ll be able to access enhanced customization
                        options, allowing you to tailor your interface and
                        workflows like never before.
                      </div>
                    </div>
                  </div>
                </TabsContent>
              )}
            </Tabs>
          </div>
        ) : (
          <>
            <div className="home-page__main-panel" data-testid="home-header">
              {announcements.announcement.forHeader ? (
                <AnnouncementHeader />
              ) : (
                <HomeHeader />
              )}
              <HomeContent />
            </div>
            <ActionItems />
          </>
        )}
      </div>
    </div>
  );
}

export default observer(HomePage);
