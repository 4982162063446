import React from "react";
import { useParams } from "react-router-dom";

import { useDashboardComponent } from "@/api/queries/dashboardComponents";
import { HomePageSearchParams } from "@/features/misc/pages/HomePage";
import { useSearchParams } from "@/hooks/useSearchParams";

export default function IssuesWithDueDatesApproachingStat() {
  const { workspace: workspaceId } = useParams<{ workspace: string }>();

  const [searchParams] = useSearchParams<HomePageSearchParams>();

  const { data: widgetData } = useDashboardComponent({
    systemName: "issues_with_due_dates_approaching",
    workspaceId: Number(workspaceId),
    params: searchParams,
  });

  const { data: overdueData } = useDashboardComponent({
    systemName: "overdue_issues",
    workspaceId: Number(workspaceId),
    params: searchParams,
  });

  return (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Issues with Due Dates Approaching
        </div>
      </div>
      <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-2">
        <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
          <div className="tw-flex tw-w-full tw-flex-col tw-items-center">
            <div className="tw-w-44 tw-text-6xl tw-font-semibold tw-text-neutral-500">
              {widgetData?.data.data?.value}
            </div>
            <div className="tw-w-44 tw-text-sm tw-font-medium tw-text-neutral-300">
              Approaching Due Dates
            </div>
          </div>
        </div>

        <div className="tw-ml-4 tw-w-48 tw-border-0 tw-border-t tw-border-solid tw-border-generals-underline" />
        <div className="tw-flex tw-w-full tw-flex-col tw-justify-center tw-gap-2">
          <div className="tw-flex tw-w-full tw-flex-col tw-items-center">
            <div className="tw-w-44 tw-text-6xl tw-font-semibold tw-text-neutral-500">
              {overdueData?.data.data?.value}
            </div>
            <div className="tw-w-44 tw-text-sm tw-font-medium tw-text-neutral-300">
              Overdue Issues
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
