import { observer } from "mobx-react";
import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import UserSettingsRoot from "@/components/settings/UserSettingsRoot";
import { ACCOUNT_ROUTES, AccountRoutes } from "@/features/accounts/pages";
import { MultiFactorAuth, ThanksForSigningUp } from "@/features/auth";
import { CreateCompany, InviteTeam, PickModules } from "@/features/company";
import Legals from "@/features/legal/pages/Legals";
import { HomePage } from "@/features/misc";
import ProjectRoutes, {
  PROJECTS_ROUTES,
} from "@/features/projects/pages/ProjectRoutes";
import { MY_TASKS_ROUTES, MyTasksRoutes } from "@/features/tasks/pages";
import Todos from "@/features/todos/pages/Todos";
import {
  WEBSITE_MONITORING_ROUTES,
  WebsiteMonitoringRoutes,
} from "@/features/website-monitoring/pages";
import DuplicateWorkspacePage from "@/features/workspace/pages/DuplicateWorkspacePage";
import { ZendeskConnect } from "@/features/zendesk";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";

import Dashboard from "../components/dashboard/Dashboard";
import DataLoading from "../components/DataLoading";
import IdleTimer from "../components/IdleTimer";
import MainContent from "../components/MainContent";
import WorkspaceReports from "../components/reports/WorkspaceReports";
import CompanySettingsRoot from "../components/settings/CompanySettingsRoot";
import CompanyTagsRoot from "../components/settings/CompanyTagsRoot";
import NotificationsCenter from "../components/settings/notificationsCenter/NotificationsCenter";
import ZendeskConfiguration from "../components/settings/zendesk-configuration";
import SettingWorkspace from "../components/settingWorkspace/SettingWorkspace";
import ComplianceTemplates from "../components/table/compliance-templates/ComplianceTemplates";
import { useMainStore } from "../contexts/Store";

function AuthenticatedRoutes() {
  const mainStore = useMainStore();
  const { pathname } = useLocation();

  const isZendeskModuleAdded = mainStore.moduleWorkspaces.isModuleAdded(
    TopLevelModule.ZENDESK,
  );

  const { workspaceID } = mainStore.context;
  const { canManageSettings, canViewReports, canManageAccounts } =
    mainStore.userPermissions;

  const websiteMonitoringFeatureEnabled = mainStore.featureFlags.getIsEnabled(
    FEATURE_FLAG_ID.COM_WEBSITE_MONITORING,
  );

  return (
    <DataLoading>
      <IdleTimer />
      <Switch>
        {/* Removes trailing slash */}
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route exact path="/">
          <Redirect to={`/workspaces/${workspaceID}/home`} />
        </Route>
        <Route path="/workspaces/:workspace/home">
          <MainContent>
            <HomePage />
          </MainContent>
        </Route>
        <Route path="/todos">
          <MainContent>
            <Todos />
          </MainContent>
        </Route>
        <Route path="/workspaces/:workspace/modules">
          <MainContent>
            <Dashboard />
          </MainContent>
        </Route>
        <Route path="/user">
          <MainContent>
            <UserSettingsRoot />
          </MainContent>
        </Route>

        {mainStore.featureFlags.getIsEnabled(FEATURE_FLAG_ID.APP_PROJECTS) && (
          <Route path={`/workspaces/:workspace_id${PROJECTS_ROUTES.base}`}>
            <MainContent>
              <ProjectRoutes />
            </MainContent>
          </Route>
        )}
        <Route path={MY_TASKS_ROUTES.base}>
          <MainContent>
            <MyTasksRoutes />
          </MainContent>
        </Route>

        {websiteMonitoringFeatureEnabled && (
          <Route path={`${WEBSITE_MONITORING_ROUTES.base}`}>
            <MainContent>
              <WebsiteMonitoringRoutes />
            </MainContent>
          </Route>
        )}
        {canViewReports && (
          <Route path="/workspace-reports/:moduleName">
            <MainContent>
              <WorkspaceReports />
            </MainContent>
          </Route>
        )}
        {canViewReports && (
          <Route path="/workspace-reports">
            <MainContent>
              <WorkspaceReports />
            </MainContent>
          </Route>
        )}
        {canManageAccounts && (
          <Route path={`/workspaces/:workspace_id${ACCOUNT_ROUTES.base}`}>
            <MainContent>
              <AccountRoutes />
            </MainContent>
          </Route>
        )}
        <Route path="/tags">
          <MainContent>
            <CompanyTagsRoot />
          </MainContent>
        </Route>
        <Route path="/resources/compliance-templates">
          <MainContent>
            <ComplianceTemplates />
          </MainContent>
        </Route>
        <Route path="/notifications">
          <MainContent>
            <NotificationsCenter />
          </MainContent>
        </Route>
        {isZendeskModuleAdded && canManageSettings && (
          <Route path="/settings/integrations/zendesk/connect">
            <ZendeskConnect />
          </Route>
        )}
        {isZendeskModuleAdded && canManageSettings && (
          <Route
            exact
            path={[
              "/settings/integrations/zendesk/configuration",
              "/settings/integrations/zendesk/configuration/:id",
            ]}
          >
            <ZendeskConfiguration />
          </Route>
        )}
        <Route path="/settings">
          <MainContent>
            <CompanySettingsRoot />
          </MainContent>
        </Route>
        <Route path="/create-company">
          <CreateCompany />
        </Route>
        <Route path="/pick-modules">
          <PickModules />
        </Route>
        <Route path="/invite-team">
          <InviteTeam />
        </Route>
        <Route path="/duplicate-workspace">
          <DuplicateWorkspacePage />
        </Route>
        <Route path="/multi-factor-auth">
          <MultiFactorAuth />
        </Route>
        <Route path="/new-workspace">
          <SettingWorkspace />
        </Route>
        <Route path="/legals">
          <Legals />
        </Route>
        <Route path="/thanks-for-signing-up">
          <ThanksForSigningUp />
        </Route>
      </Switch>
    </DataLoading>
  );
}

export default observer(AuthenticatedRoutes);
